import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container } from 'react-grid-system';
import ReactMarkdown from 'react-markdown';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/referral-hero.jpg';
import OneCol from '../components/rows/onecol/index';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';
// import CTA from '../components/rows/cta/index'
import Column from '../components/elements/column';

import EmailHeart from '../icons/email.svg';
import FaxHeart from '../icons/fax-heart.svg';
import PencilHeart from '../icons/pencil-heart.svg';

export default function Referrals({ data }) {
  return (
    <Layout>
      <Seo
        title="Referrals"
        description="Refer your patients to PACE Cardiology. We offer full-service cardiac care, diagnostics and heart health 
          supports."
      />
      <Hero title="Referrals" banner={Banner} />
      {data.allStrapiReferrals.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            title={node.ref1.title}
            description={node.ref1.description}
            link={node.ref1.button_title}
            href={node.ref1.button_link}
            image={node.ref1.image.localFile.publicURL}
          />
          <div className="bg-gray-100 py-52 mt-56 medical-bg">
            <Container className="flex flex-wrap justify-around">
              <div className="flex flex-col flex-wrap mb-40">
                <ReactMarkdown
                  className="md:w-2/3 mx-auto text-center mb-5"
                  children={`How to *refer* your patients`}
                  components={{
                    p: 'h2',
                  }}
                  linkTarget="_blank"
                />
                <p className="md:w-2/3 mx-auto text-center">
                  Our world-renowned, experienced cardiac physicians provide
                  highly specialized care. Patients can choose in-person or
                  telemedicine (online or phone) appointments.{' '}
                </p>
              </div>
              <Column
                icon={<EmailHeart />}
                title={node.ref3.col1_title}
                description={node.ref3.col1_description}
                isCard
              />
              <Column
                icon={<FaxHeart />}
                title={node.ref3.col2_title}
                description={node.ref3.col2_description}
                isCard
              />
              <Column
                icon={<PencilHeart />}
                title={node.ref3.col3_title}
                description={node.ref3.col3_description}
                isCard
              />
            </Container>
          </div>
        </React.Fragment>
      ))}
      <div className="no-margin">
        <OneCol
          blueBG
          pdf
          newTab
          title="Need to make a referral to PACE? Find our forms here"
          link="Patient Referral Form"
          href="/pace-patient-referral-form.pdf"
          target="_blank"
          link2="Orillia ECHO Referral"
          href2="/pace-cardiology-orillia-referral-form-2023.pdf"
        />
      </div>
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query Referrals {
    allStrapiReferrals {
      edges {
        node {
          id # id of the node
          ref1 {
            id
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
          ref2 {
            col1_title
            col2_title
            col3_title
            col1_description
            col2_description
            col3_description
          }
          ref3 {
            col1_title
            col2_title
            col3_title
            col1_description
            col2_description
            col3_description
          }
        }
      }
    }
  }
`;
