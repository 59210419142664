import React from 'react';
// import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from 'react-grid-system';
import Button from '../../elements/button';

function LinkRenderer(props) {
  return (
    <a href={`/#${props.href}`} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

export default function BannerRow(props) {
  return (
    <div
      className={
        props.blueBG
          ? `covid ${
              props.covid ? 'covid-bg' : ''
            } bg-blue-500 white-text py-40 mb-56`
          : 'onecol-container'
      }
    >
      <Container>
        <Row>
          <Col md={8} sm={12} className="mx-auto text-center">
            <h2 className={props.link2 ? `mb-10` : ''}>{props.title}</h2>
            <ReactMarkdown
              children={props.description}
              className="mb-10"
              components={{ a: LinkRenderer }}
            />
            <div
              className={`${
                props.link2
                  ? `mx-auto max-w-screen-sm flex justify-center space-x-4`
                  : null
              }`}
            >
              {!props.pdf ? (
                <>
                  <Button redfilled link={props.link} href={props.href} />
                  {props.link2 ? (
                    <>
                      <Button redfilled link={props.link2} href={props.href2} />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noreferrer"
                    className={`bg-red-600 inline-flex p-6 rounded-lg duration-300 justify-center 
                                        ${
                                          props.red
                                            ? 'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white'
                                            : 'border-2 border-gray-700 bg-gray-700 text-white hover:bg-green-500'
                                        } 
                                        ${
                                          props.redfilled
                                            ? 'bg-red-500 border-red-500 hover:border-gray-700'
                                            : ''
                                        } 
                                        ${
                                          props.ghost
                                            ? `bg-none text-gray-700 hover:text-white`
                                            : ''
                                        }`}
                  >
                    {props.link}
                  </a>
                  {props.link2 ? (
                    <>
                      <a
                        rel="noreferrer"
                        href={props.href2}
                        target="_blank"
                        className={`bg-red-600 inline-flex p-6 rounded-md duration-300 justify-center 
                                                ${
                                                  props.red
                                                    ? 'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white'
                                                    : 'border-2 border-gray-700 bg-gray-700 text-white hover:bg-green-500'
                                                } 
                                                ${
                                                  props.redfilled
                                                    ? 'bg-red-500 border-red-500 hover:border-gray-700'
                                                    : ''
                                                } 
                                                ${
                                                  props.ghost
                                                    ? `bg-none text-gray-700 hover:text-white`
                                                    : ''
                                                }`}
                      >
                        {props.link2}
                      </a>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
